document.addEventListener('DOMContentLoaded', function () {
  let device_class = '.is-pc';
  if (document.body.clientWidth < 1280) {
    device_class = '.is-mobile';
  }
  const truck = document.querySelector(device_class + ' #truck');
  const startX = 750; // X座標の始点
  const startY = 110; // Y座標の始点
  const endX = 260; // X座標の目標点
  const endY = 360; // Y座標の目標点
  let diffX = endX - startX; // X座標の変化量
  let isXPlus = 1;
  if (Math.sign(diffX)) {
    diffX = diffX * -1;
    isXPlus = 0;
  }

  let diffY = endY - startY; // Y座標の変化量
  let isYPuls = 1;
  if (Math.sign(diffY)) {
    diffY = diffY * -1;
    isYPuls = 0;
  }

  const time = 10000; // 変化にかける時間 (ミリ秒)

  // アップデートを実行する
  requestAnimationFrame(update);

  function update(timestamp) {
    // 進捗率を算出
    let progress = timestamp / time;

    // 進捗率が100%を超えないよう丸める
    progress = Math.min(progress, 1);

    // 値を算出し反映する
    const resultX = isXPlus ? startX + diffX * progress : startX - diffX * progress; // X座標
    const resultY = isYPuls ? startY + diffY * progress : startY - diffY * progress; // Y座標

    //truck.setAttribute('transform', 'translate(' + resultX + ' ' + resultY + ')');
    truck.setAttribute('x', resultX);
    truck.setAttribute('y', resultY);

    // 進捗が1未満の場合はアップデートを再実行する
    if (progress < 1) {
      requestAnimationFrame(update);
    }
  }
});
